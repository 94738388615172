import React, { useEffect, useState, useContext } from "react";
import LectureCard from "../components/LectureCard";
import { AuthContext } from "../context/auth-context";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import { Typography } from "@mui/material";

const Lectures = () => {
  const auth = useContext(AuthContext);

  const [lectures, setLectures] = useState([]);

  useEffect(() => {
    const getLectures = async () => {
      await axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}lecture/getUserLecturesByYear/${auth.year}`,
          {
            headers: { Authorization: `Bearer ${auth.token}` },
          }
        )
        .then((response) => {
          setLectures(response.data.lectures);
        })
        .catch((error) => {
          console.error(error);
          if (error.response && error.response.status === 403) {
            localStorage.removeItem("userData");
          }
        });
    };
    getLectures();
  }, [auth]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        width: "80%",
        marginTop: "100px",
        marginLeft: "10%",
        direction: "rtl",
      }}
    >
      <Typography
        variant="h4"
        style={{
          marginBottom: "40px",
          fontWeight: "700",
          paddingBottom: "20px",
          textAlign: "center",
        }}
      >
        محاضرات تم شرائها
      </Typography>
      <Grid
        container
        spacing={{ xs: 2, md: 6 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        {lectures.length > 0 ? (
          lectures.map((lec) => {
            if (lec.isPaid) {
              return (
                <Grid key={lec.id} item xs={4} sm={4} md={4}>
                  <LectureCard lec={lec} />
                </Grid>
              );
            }
          })
        ) : (
          <div
            style={{
              display: "flex",
              marginTop: "10%",
              marginLeft: "35%",
              marginRight: "35%",
            }}
          >
            <Alert severity="error">
              انتظر قليلا او لاتوجد محاضرات لهذة المرحلة
            </Alert>
          </div>
        )}
      </Grid>
      <div
        style={{
          width: "80vw",
          height: "2px",
          backgroundColor: "#e0e0e0",
          marginTop: "100px",
          marginBottom: "100px",
          justifyContent: "center",
        }}
      ></div>
      <Typography
        variant="h4"
        style={{
          marginBottom: "40px",
          marginTop: "10px",
          fontWeight: "700",
          paddingBottom: "20px",
          textAlign: "center",
        }}
      >
        محاضرات لم يتم شرائها
      </Typography>
      <Grid
        container
        spacing={{ xs: 2, md: 6 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        style={{ paddingBottom: "100px" }}
      >
        {lectures.length > 0 &&
          lectures.map((lec) => {
            if (!lec.isPaid) {
              return (
                <Grid key={lec.id} item xs={4} sm={4} md={4}>
                  <LectureCard lec={lec} />
                </Grid>
              );
            }
          })}
      </Grid>
    </Box>
  );
};

export default Lectures;
