import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Checkbox, FormControlLabel, MenuItem } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import axios from "axios";
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ControlledAccordions from "../components/Accordion";
import Banner from "../components/Banner/Banner";

export default function SignUp() {
  const navigate = useNavigate();
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    const getData = async () => {
      await axios
        .get(`${process.env.REACT_APP_BACKEND_URL}user/get-years`)
        .then((response) => {
          setYears(response.data.years);
        })
        .catch((error) => {
          console.error(error);
        });
    };
    getData();
  }, []);

  const handleChangeYear = (event) => {
    setSelectedYear(event.target.value);
    setFormData({
      ...formData,
      year: event.target.value._id,
    });
  };

  const [formData, setFormData] = useState({
    email: "",
    phoneNumber: "",
    dadPhoneNumber: "",
    password: "",
    firstName: "",
    lastName: "",
    year: "",
    confirmPassword: "",
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const [errors, setErrors] = useState({});

  const handleSubmit = async (event) => {
    event.preventDefault();
    const fieldErrors = validateFields(formData);
    if (Object.keys(fieldErrors).length === 0) {
      await axios
        .post(`${process.env.REACT_APP_BACKEND_URL}user/signup`, formData)
        .then((response) => {
          navigate("/auth/login");
        })
        .catch((error) => {
          console.error(error);
        });

      setFormData({
        email: "",
        phoneNumber: "",
        dadPhoneNumber: "",
        password: "",
        firstName: "",
        lastName: "",
        year: "",
        confirmPassword: "",
      });
    } else {
      // email is invalid, display an error message
      setErrors(fieldErrors);
    }
  };

  const validateFields = (formData) => {
    const errors = {};

    if (!formData.firstName.trim()) {
      errors.firstName = "يجب ادخال الاسم الاول";
    }
    if (!formData.lastName.trim()) {
      errors.lastName = "يجب ادخال الاسم الأخير";
    }
    if (!formData.phoneNumber.trim()) {
      errors.phoneNumber = "يجب ادخال  رقم الهاتف";
    }
    if (!formData.dadPhoneNumber.trim()) {
      errors.dadPhoneNumber = "يجب ادخال  رقم الهاتف";
    }
    if (!formData.year.trim()) {
      errors.year = "يجب ادخال الصف الدراسي";
    }
    if (!formData.password.trim()) {
      errors.password = "يجب ادخال";
    }
    if (!formData.email.trim()) {
      errors.email = "يجب ادخال حساب شخصي";
    } else if (!validateEmail(formData.email)) {
      errors.email = "يجب ادخال حساب شخصي صحيح";
    }

    if (!formData.password.trim()) {
      errors.password = "يجب ادخال كلمة المرور";
    } else if (formData.password.trim().length < 8) {
      errors.password = "كلمة المرور يجب ان تكون اكثر من 8 حروف";
    }

    if (!formData.confirmPassword.trim()) {
      errors.confirmPassword = "يجب ادخال تأكيد كلمة المرور";
    } else if (formData.password !== formData.confirmPassword) {
      errors.confirmPassword = " يجب ان تكون تأكيد كلمة المرور مطابقة";
    }

    return errors;
  };

  const validateEmail = (email) => {
    // use a regular expression to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <div>
      <Banner st={"login"} />
      <Container style={{ width: "60vw" }} component="main" maxWidth="x">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography
            style={{
              direction: "rtl",
              marginTop: "5px",
              display: "flex",
              justifyContent: "center",
              marginBottom: "15px",
            }}
            component="h1"
            variant="h4"
          >
            إنشاء حساب جديد
          </Typography>
          <Typography
            style={{
              direction: "rtl",
              marginTop: "5px",
              display: "flex",
              justifyContent: "center",
              fontWeight: "800",
            }}
            component="h4"
            variant="h6"
          >
            مرحبا بكم فى الموقع الرسمي للأستاذ ممدوح الفحل
          </Typography>

          <Box
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2} style={{ direction: "rtl" }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="الاسم الأول"
                  inputProps={{ dir: "rtl" }}
                  InputLabelProps={{ dir: "rtl" }}
                  value={formData.firstName}
                  onChange={handleChange}
                  error={!!errors.firstName}
                  helperText={errors.firstName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="الاسم الأخير"
                  name="lastName"
                  inputProps={{ dir: "rtl" }}
                  InputLabelProps={{ dir: "rtl" }}
                  value={formData.lastName}
                  onChange={handleChange}
                  error={!!errors.lastName}
                  helperText={errors.lastName}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="البريد الإلكترونى"
                  name="email"
                  autoComplete="email"
                  inputProps={{ dir: "rtl" }}
                  InputLabelProps={{ dir: "rtl" }}
                  value={formData.email}
                  onChange={handleChange}
                  error={!!errors.email}
                  helperText={errors.email}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="phoneNumber"
                  label="رقم الهاتف المحمول"
                  name="phoneNumber"
                  autoComplete="phoneNumber"
                  inputProps={{ dir: "rtl" }}
                  InputLabelProps={{ dir: "rtl" }}
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  error={!!errors.phoneNumber}
                  helperText={errors.phoneNumber}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="dadPhoneNumber"
                  label=" رقم الهاتف المحمول الأب"
                  name="dadPhoneNumber"
                  autoComplete="dadPhoneNumber"
                  inputProps={{ dir: "rtl" }}
                  InputLabelProps={{ dir: "rtl" }}
                  value={formData.dadPhoneNumber}
                  onChange={handleChange}
                  error={!!errors.dadPhoneNumber}
                  helperText={errors.dadPhoneNumber}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  select
                  required
                  fullWidth
                  label="الصف الدراسي"
                  inputProps={{ dir: "rtl" }}
                  InputLabelProps={{ dir: "rtl" }}
                  value={selectedYear}
                  onChange={handleChangeYear}
                  error={!!errors.year}
                  helperText={errors.year}
                >
                  {years.map((item) => (
                    <MenuItem key={item.value} value={item}>
                      {item.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="كلمة المرور"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  value={formData.password}
                  onChange={handleChange}
                  error={!!errors.password}
                  helperText={errors.password}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="confirmPassword"
                  label=" تأكيد كلمة المرور"
                  type="password"
                  id="confirmPassword"
                  autoComplete="new-password"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  error={!!errors.confirmPassword}
                  helperText={errors.confirmPassword}
                />
              </Grid>
            </Grid>
            <div style={{ margin: "30px" }}>
              <ControlledAccordions />
            </div>
            <FormControlLabel
              dir="rtl"
              control={
                <Checkbox
                  checked={isChecked}
                  onChange={(e) => setIsChecked(e.target.checked)}
                  name="checkbox-agree"
                  color="primary"
                />
              }
              label=" انا اوافق على الشروط و الاحكام"
            />
            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={!isChecked}
            >
              تسجيل
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/auth/login")}
                  variant="body1"
                >
                  لديك بالفعل حساب سجل الدخول
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </div>
  );
}
