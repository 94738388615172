import React from "react";
import { useContext } from "react";
import { CartContext } from "../context/cart-context";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, Typography } from "@mui/material";
import axios from "axios";
import { AuthContext } from "../context/auth-context";

const Cart = () => {
  const { getAllCartItems, removeItemByIdFromCart, clearCart } =
    useContext(CartContext);
  const cartItems = getAllCartItems();
  const auth = useContext(AuthContext);

  const BuyCart = async () => {
    let lectures = [];
    cartItems.forEach((element) => {
      lectures.push(element.id);
    });
    let formData = {
      lectures,
    };

    await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}order/placeOrder`, formData, {
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then((response) => {
        let message = "أريد شراء المحاضرات التالية: ";
        cartItems.forEach((element) => {
          message += element.name + " ";
        });
        const email = auth.email;
        message += "البريد الالكتروني: " + email;
        console.log(message);

        clearCart();
        let url = `https://wa.me/201068911722`;
        url += `?text=${encodeURIComponent(message)}`;
        window.open(url);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div style={{ paddingBottom: "20%" }}>
      {cartItems.length > 0 ? (
        <TableContainer style={{ direction: "rtl" }} component={Paper}>
          <div
            style={{
              margin: "5%",
              paddingTop: "40px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Table size="medium" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "600" }} align="right">
                    الأسم
                  </TableCell>
                  <TableCell style={{ fontWeight: "600" }} align="right">
                    صورة الفيديو
                  </TableCell>
                  <TableCell style={{ fontWeight: "600" }} align="right">
                    السعر
                  </TableCell>
                  <TableCell style={{ fontWeight: "600" }} align="left">
                    الغاء الاضافة
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cartItems.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell
                      style={{ fontSize: 20, fontWeight: "500" }}
                      align="right"
                      component="th"
                      scope="row"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell align="right">
                      <img
                        width={"100px"}
                        height={"100px"}
                        src={row.img}
                        alt={row.name}
                      />
                    </TableCell>
                    <TableCell
                      style={{ fontSize: 20, fontWeight: "500" }}
                      align="right"
                    >
                      {row.price}
                    </TableCell>
                    <TableCell style={{ fontSize: 20 }} align="left">
                      <Button
                        onClick={() => {
                          removeItemByIdFromCart(row.id);
                        }}
                        size="large"
                        style={{
                          marginTop: "40px",
                          fontWeight: "500",
                          fontSize: 20,
                        }}
                        variant="contained"
                      >
                        ازالة المحاضرة
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Button
              onClick={BuyCart}
              size="large"
              style={{ marginTop: "40px", fontWeight: "500", fontSize: 20 }}
              variant="contained"
            >
              شراء الفيديوهات المعروضة في الجدول
            </Button>
          </div>
        </TableContainer>
      ) : (
        <Typography
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "10%",
          }}
          variant="h6"
        >
          يجب اضافة بعض المحاضرات هنا لتتم عملية الشراء
        </Typography>
      )}
    </div>
  );
};

export default Cart;
