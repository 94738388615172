import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { CartContext } from "../../context/cart-context";
import PopupDialog from "./addToCartPopup";

export default function LectureCard({ lec }) {
  const navigate = useNavigate();
  const { addItemToCart } = useContext(CartContext);
  const [open, setOpen] = React.useState(false);
  const addToCart = () => {
    const item = {
      id: lec.id,
      name: lec.title,
      price: lec.price,
      img: lec.imageUrl,
    };
    addItemToCart(item);
  };

  const handleClick = () => {
    if (lec.isPaid) {
      navigate(`/lecture/${lec.id}`);
    } else {
      setOpen(true);
    }
  };

  return (
    <>
      <Card
        onClick={handleClick}
        sx={{ maxWidth: 345, direction: "rtl", borderRadius: "20px" }}
      >
        <CardActionArea>
          <CardMedia
            component="img"
            height="140"
            image={lec.imageUrl}
            alt="green iguana"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {lec.title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {lec.description}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button
            onClick={handleClick}
            style={{ fontWeight: "600" }}
            color="primary"
          >
            {lec.isPaid ? "مشاهدة" : `شراء مقابل  ${lec.price} جنيها `}
          </Button>
        </CardActions>
      </Card>
      <PopupDialog addToCart={addToCart} open={open} setOpen={setOpen} />
    </>
  );
}
