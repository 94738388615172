import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import Banner from "../components/Banner/Banner";
import { AuthContext } from "../context/auth-context";
import axios from "axios";

export default function Login() {
  const [email, setEmail] = React.useState("");
  const [pass, setPass] = React.useState("");
  const [error, setError] = React.useState(false);

  const navigate = useNavigate();
  const auth = React.useContext(AuthContext);

  const handleSubmit = async (event) => {
    event.preventDefault();

    let formData = {
      email: email,
      password: pass,
    };

    await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}user/login`, formData)
      .then((response) => {
        auth.login(
          response.data.userId,
          response.data.token,
          response.data.year,
          response.data.expirationDate,
          response.data.email
        );
      })
      .catch((error) => {
        console.error(error);
        setError(true);
      });

    setEmail("");
    setPass("");
  };

  return (
    <div>
      <Banner st={"login"} />

      <Container component="main" maxWidth="x">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography
            style={{
              direction: "rtl",
              marginTop: "5px",
              display: "flex",
              justifyContent: "center",
              marginBottom: "15px",
            }}
            component="h1"
            variant="h4"
          >
            تسجيل دخول
          </Typography>
          <Typography
            style={{
              direction: "rtl",
              marginTop: "5px",
              display: "flex",
              justifyContent: "center",
              fontWeight: "800",
            }}
            component="h4"
            variant="h6"
          >
            مرحبا بكم فى الموقع الرسمي للأستاذ ممدوح الفحل
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            {error && (
              <Typography
                style={{
                  direction: "rtl",
                  marginTop: "5px",
                  marginBottom: "5px",
                  display: "flex",
                  justifyContent: "center",
                  color: "red",
                }}
                component="h4"
                variant="h6"
              >
                خطأ في البريد الالكتروني او كلمة المرور
              </Typography>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  value={email}
                  label="البريد الالكتروني"
                  name="email"
                  autoComplete="email"
                  inputProps={{ dir: "rtl" }}
                  InputLabelProps={{ dir: "rtl" }}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  value={pass}
                  name="password"
                  label="كلمة المرور"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  inputProps={{ dir: "rtl" }}
                  InputLabelProps={{ dir: "rtl" }}
                  onChange={(e) => setPass(e.target.value)}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              تسجبل الدخول
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/auth/signup")}
                  variant="body2"
                >
                  ليس لديك حساب انشاء حساب
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </div>
  );
}
