import React, { useContext } from "react";
import { AppBar, Button, Toolbar, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import "./Header.css";
import logo from "./logo.ico";
import { CartContext } from "../../context/cart-context";
const Header = ({ token }) => {
  const navigate = useNavigate();
  const { getAllCartItems } = useContext(CartContext);
  const cartItems = getAllCartItems();

  return (
    <AppBar style={{ direction: "rtl" }}>
      <Toolbar
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          backgroundColor: "white",
        }}
      >
        <div style={{ cursor: "pointer" }} onClick={() => navigate("/")}>
          <img src={logo} alt="logo" width={70} height={50} />
        </div>
        <div>
          {!token ? (
            <Button
              style={{ fontWeight: "800", fontSize: "20px" }}
              color="primary"
              variant="outlined"
              size="md"
              onClick={() => navigate("/auth/login")}
            >
              تسجيل الدخول
            </Button>
          ) : (
            <Button
              style={{ fontWeight: "800", fontSize: "14px" }}
              color="primary"
              variant="outlined"
              size="md"
              onClick={() => navigate("/cart")}
            >
              الدروس و المشتريات ( {cartItems?.length} )
            </Button>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
