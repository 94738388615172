import React from "react";
import Banner from "../components/Banner/Banner";
import SecTitle from "../components/SecTitle/SecTitle";
import Card from "../components/Card/Card";
const Home = () => {
  return (
    <div>
      <Banner />
      <SecTitle title="عن الأستاذ ممدوح الفحل" />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Card />
      </div>
    </div>
  );
};

export default Home;
