import { createContext, useState, useEffect } from 'react';

export const CartContext = createContext();

const CartContextProvider = ({ children }) => {
    const [cartItems, setCartItems] = useState([]);
    const [cartItemsLoaded, setCartItemsLoaded] = useState(false);

    useEffect(() => {
        // Load cart items from local storage
        const storedCartItems = JSON.parse(localStorage.getItem('cartItems'));
        if (storedCartItems) {
            setCartItems(storedCartItems);
        }
        setCartItemsLoaded(true);
    }, []);

    const addItemToCart = (item) => {
        const isItemInCart = cartItems.some((cartItem) => cartItem.id === item.id);
        if (!isItemInCart) {
            setCartItems([...cartItems, item]);
        }
    };
    const removeItemByIdFromCart = (itemId) => {
        const newCartItems = cartItems.filter((cartItem) => cartItem.id !== itemId);
        setCartItems(newCartItems);
    };
    const removeItemFromCart = (item) => {
        const newCartItems = cartItems.filter((cartItem) => cartItem.id !== item.id);
        setCartItems(newCartItems);
    };

    const clearCart = () => {
        setCartItems([]);
    };

    const getAllCartItems = () => {
        return cartItems;
    };

    // Save cart items to local storage whenever they change
    useEffect(() => {
        if (cartItemsLoaded) {
            localStorage.setItem('cartItems', JSON.stringify(cartItems));
        }
    }, [cartItemsLoaded, cartItems]);

    return (
        <CartContext.Provider
            value={{
                cartItems,
                addItemToCart,
                removeItemFromCart,
                clearCart,
                getAllCartItems,
                removeItemByIdFromCart
            }}
        >
            {children}
        </CartContext.Provider>
    );
};

export default CartContextProvider;
