import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

function PopupDialog({ addToCart, open, setOpen }) {
  const handleClose = () => {
    addToCart();
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>إضافة إلى السلة</DialogTitle>
        <DialogContent>هل تريد إضافة العنصر إلى السلة؟</DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}
            color="primary"
          >
            إغلاق
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            إضافة إلى السلة
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default PopupDialog;
