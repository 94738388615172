import React from 'react';

import './Footer.css';

function Footer() {

  return (
    <footer className="site-footer">
      <div className="container">
        <div className="row">



          <div className="col-xs-6 col-md-3">
            <h6>روابط سريعة</h6>
            <ul className="footer-links">
              <li><a href="/">المحاضرات</a></li>
              <li><a href="/">الامتحانات</a></li>
            </ul>
          </div>
        </div>
        <hr />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-sm-6 col-xs-12">
            <p className="copyright-text">Copyright &copy; 2023 All Rights Reserved
            </p>
          </div>

          <div className="col-md-4 col-sm-6 col-xs-12">
            <ul className="social-icons">
              <li><a className="facebook" href="#"><i className="fa fa-facebook"></i></a></li>
              <li><a className="twitter" href="#"><i className="fa fa-twitter"></i></a></li>
              <li><a className="dribbble" href="#"><i className="fa fa-dribbble"></i></a></li>
              <li><a className="linkedin" href="#"><i className="fa fa-linkedin"></i></a></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )

}

export default Footer;