import React, { useEffect, useState } from "react";
import background from "./1.png";

const Banner = ({ st }) => {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  return (
    <div>
      <img
        src={background}
        alt=""
        style={{
          width: "100%",
          height: width <= 768 ? "auto" : "700px",
          objectFit: "fill",
        }}
      />
    </div>
  );
};

export default Banner;
