import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Alert } from '@mui/material';

export default function ControlledAccordions() {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div style={{ direction: "rtl" }} >
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                        التسجيل
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        1- يلتزم الطالب بتسجيل بياناته الصحيحة دون نقصان أو خطأ.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>الحضور</Typography>
                    
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        1- يلتزم الطالب بالقواعد الموضوعة من خلال الأستاذ حتى يتسنى له التكلمة معنا والحضور.
                    </Typography>
                    <Typography>
                        2- عدم الغياب أكثر من محاضرة حتى لا يتعرض الطالب للحظر.                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                        المحاضرات
                    </Typography>
                    
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        1- يلتزم كل طالب بأن يتصفح الموقع والمحاضرات من حسابه الشخصي المسجل
                    </Typography>
                    <Typography>
                        2- يلتزم الطالب بأن يشاهد المحاضرات في وقتها.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>شروط عامة</Typography>
                    
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        1- يلتزم كل طالب بعدم تصوير أو تسريب المحاضرات المصورة على الموقع.
                    </Typography>
                    <Typography>
                        2- يلتزم الطالب باحترام الأستاذ وعدم استخدام المادة العلمية استخدام سيء.
                    </Typography>
                    <Typography>
                        3- يلتزم الطالب بعدم التغيير في محتوى الموقع تحت أي ظرف كان.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Alert style={{ margin: "10px" }} variant="outlined" severity="error">
                من يخالف هذه الشروط سيتم حظر حسابه
            </Alert>
        </div>
    );
}