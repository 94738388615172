import { CardActionArea } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Chevrolet from "../../images/5.png";

export default function MultiActionAreaCard() {
  return (
    <Card sx={{ maxWidth: 600, marginTop: "40px", marginBottom: "40px" }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="700"
          image={Chevrolet}
          alt="green iguana"
          style={{ objectFit: "contain" }}
        />
        <CardContent>
          <Typography
            style={{ direction: "rtl" }}
            gutterBottom
            variant="h5"
            component="div"
          >
            ممدوح بك الكبير
          </Typography>
          <Typography
            style={{ direction: "rtl", fontWeight: "600" }}
            variant="body1"
            color="text.secondary"
          >
            الأستاذ ممدوح الفحل، مُعلِّم أول : تخرَّج في كُلية الآداب بتقدير
            امتياز مع مرتبة الشرف و أول دفعتة و حاصل علي دبلوم تربوي جامعة عين
            شمس ، خبرة خمسة عشر عامًا في تدريس. قام بتدريس مناهج الثَّانوية
            العامة باختلاف أشكالِها. لا يخلو عامٌ بفضل الله إلا وعنده عدد من
            الأوائل.
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
