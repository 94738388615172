import React, { useEffect } from "react";
import Header from "./components/Header/Header";
import Home from "./Pages/Home";
import AuthSignup from "./Pages/AuthSignup";
import AuthLogin from "./Pages/AuthLogin";
import Lectures from "./Pages/Lectures";
import { createTheme, ThemeProvider } from "@mui/material";
import "./App.css";
import Footer from "./components/Footer/Footer";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { AuthContext } from "./context/auth-context";
import CartContextProvider from "./context/cart-context";
import { useAuth } from "./hooks/auth-hook";
import Cart from "./Pages/Cart";
import Lecture from "./Pages/Lecture";
import devtools from "devtools-detect";

const theme = createTheme({
  typography: {
    fontFamily: ["Almarai", "sans-seri"].join(","),
  },
  direction: "rtl",
});

document.addEventListener("contextmenu", function (event) {
  event.preventDefault();
});
const App = () => {
  useEffect(() => {
    document.onkeydown = function (e) {
      if (e.keyCode == 123) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode == 73) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode == 74) {
        return false;
      }
      if (e.ctrlKey && e.keyCode == 85) {
        return false;
      }
    };
  }, [devtools.isOpen]);
  const { login, logout, token, userId, year, email } = useAuth();
  let routes;
  if (token) {
    routes = (
      <>
        <Header token={token} />
        <div style={{ marginTop: "60px" }}>
          <Routes>
            <Route path="/lectures" element={<Lectures />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/lecture/:lectureId" element={<Lecture />} />
            <Route path="*" element={<Navigate to="/lectures" replace />} />
          </Routes>
        </div>
      </>
    );
  } else {
    routes = (
      <>
        <Header token={token} />
        <div style={{ marginTop: "60px" }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/auth/signup" element={<AuthSignup />} />
            <Route path="/auth/login" element={<AuthLogin />} />
            <Route path="*" element={<Navigate to="/auth/login" replace />} />
          </Routes>
          <Footer />
        </div>
      </>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        year,
        login: login,
        logout: logout,
        email: email,
      }}
    >
      <CartContextProvider>
        <ThemeProvider theme={theme}>
          <div className="app">
            <BrowserRouter>{routes}</BrowserRouter>
          </div>
        </ThemeProvider>
      </CartContextProvider>
    </AuthContext.Provider>
  );
};

export default App;
