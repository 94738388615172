import { Typography } from "@mui/material";
import React from "react";

const SecTitle = ({ title }) => {
  return (
    <div
      style={{ display: "flex", alignItems: "center", flexDirection: "column" }}
    >
      <Typography
        style={{
          fontWeight: "800",
          fontSize: "24px",
          marginTop: "40px",
        }}
      >
        {title}
      </Typography>
      <div
        style={{
          width: "100px",
          height: "3px",
          backgroundColor: "#537FE7",
          marginTop: "8px",
        }}
      ></div>
    </div>
  );
};

export default SecTitle;
